import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'PlugIt',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: 200,
  catalogTalents: true,
  has_hours: false,
  map_hide: true,
};

export default config;
